@use 'sass:color';

.photoViewer {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background-color: black;
	display: grid;
	grid-template-rows: auto 1fr auto;

	&:focus {
		outline: none;
	}

	.actionBar {
		position: relative;
		width: 100%;
		padding: 15px;
		display: flex;
		justify-content: center;
	}

	.ratingBar {
		display: flex;
		justify-content: center;
		align-items: center;

		& > * + * {
			margin-left: 15px;
		}
		.starRatingItem {
			color: gold;
			&:hover {
				color: lighten(gold, 50%);
			}
		}
		.clearRating {
			color: #ccc;

			&:hover {
				color: lighten(#ccc, 10%);
			}
		}
	}

	.closeImage {
		position: absolute;
		top: 15px;
		right: 15px;
		text-decoration: none;
		cursor: pointer;
		color: white;
		background-color: black;
		justify-content: flex-end;
	}

	img {
		max-height: 100%;
		max-width: 100%;
		object-fit: contain;
	}
}
