.photoSetList {
	overflow-x: hidden;
	overflow-y: scroll;
	height: 100%;
	display: flex;
	flex-wrap: wrap;
	gap: 5px;
	justify-content: center;
}

.photoSetsContainer {
}
.processingOverlay {
	z-index: 20;
	background-color: black;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0.75;
	display: flex;
	justify-content: center;
	padding-top: 15%;
}
