.PhotoSetInfo {
	padding: 25px 15px;
	background-color: #00000080;
}

.PhotoGridContainer {
	padding: 25px;
}

.PhotoSetThumbLarge {
	width: 200px;
	height: 200px;
	position: relative;

	.textContainer {
		position: absolute;
		top: 0px;
		padding: 10px;
		//background-color: #00000030;
		font-size: 14px;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: flex-end;
		align-items: flex-start;

		flex-direction: column;
		flex-wrap: nowrap;
		align-content: center;
		background: rgb(0, 0, 0);
		background: linear-gradient(
			0deg,
			#000000 0%,
			rgba(255, 255, 255, 0) 35%
		);

		border: solid 1px #442d6b;
	}

	.coverPhoto {
		height: 200px;
		width: 200px;
		object-fit: cover;
		display: block;
		background-color: black;

		.notProcessed {
			opacity: 0.75;
		}
	}

	.publishIcon {
		z-index: 10;
		position: absolute;
		top: 5px;
		right: 5px;
	}
}
